import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AppLayout from './layouts/AppLayout'
import './assets/tailwind.css'
import 'tw-elements';
import eventBus from './plugins/event-bus';

createApp(App).use(store).use(router).use(eventBus).component('AppLayout', AppLayout).mount('#app')
