import { createStore } from 'vuex'
import persistedStateVuex from "persisted-state-vuex"

export default createStore({
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false
  },
  getters: {
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      state.isUserLoggedIn = !!(token)
    },
    setUser (state, user) {
      state.user = user
    }
  },
  actions: {
    setToken ({commit}, token) {
      commit('setToken', token)
    },
    setUser ({commit}, user) {
      commit('setUser', user)
    }
  },
  modules: {
  },
  plugins: [persistedStateVuex.init]
})
