<template>
  <div>
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template> 

<style>

</style>
