<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import AppLayoutDefault from './AppLayoutDefault.vue'
import { markRaw, shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'AppLayout',
  setup() {
    const layout = shallowRef({})
    layout.value = markRaw(AppLayoutDefault)
    const route = useRoute()

    watch( () => route.meta, async (meta) => {
      try {
        const component = await import(`@/layouts/${ meta.layout }.vue`)
        layout.value = component?.default || AppLayoutDefault
      } catch (e) {
        layout.value = AppLayoutDefault
      }
    },{ immediate: true })
    return { layout }
  }
}
</script>